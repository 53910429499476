* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
  height: 100%;
}

#root {
  height: 100%;
}

:root {
  --clr-blue: #0133a2;
  --clr-white: #fff;
  --clr-gray: #EFEFEF;
  --clr-gray-text: #cccccc;
  --clr-orange: #fe801a;
  --clr-light-green: #5caf51;
  --clr-dark-green: #187100;
  --clr-text-black: #383838;
  --clr-black-button: #2F2F2F;
  --font-family: 'Raleway', sans-serif;
  --h1-fontsize: 40px;
  --h2-fontsize: 24px;
  --span-text-card: 25px;
  --span-h1: 28px;
  --span-big: 65px;
  --h3-fontsize: 20px;
  --p-fontsize: 16px;
  --p-fontsize-small: 14px;
  --a-fontsize: 13px;
  --number-fontsize: 64px;
  --clr-responsive: #2b375a;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.logo {
  width: 250px;
}

a {
  text-decoration: none;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--a-fontsize);
  font-family: var(--font-family);
  letter-spacing: 0.5px;
  transition: .2s color ease;
}
a,
p,
h1,
h2,
h3,
h4,
span,
ul,
li{
  font-family: var(--font-family);
}

a:hover {
  color: var(--clr-gray-text);
}

header ul,
header li {
  text-decoration: none;
  list-style: none;
}

p {
  line-height: 1.7;
  font-weight: 400;
  font-size: 17px;
  display: block;
}


span {
  font-size: 17px;
}

h1 {
  font-size: var(--h1-fontsize);
  font-weight: 700;
}

h2 {
  font-size: var(--h2-fontsize);
}

h3 {
  font-size: var(--h3-fontsize);
}

.row {
  display: flex;
  gap: 50px;
}

.bg-gray {
  background-color: var(--clr-gray);
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: baseline;
}

.tb {
  color: var(--clr-text-black);
}

.ds {
  width: 30%;
}

.dl {
  width: 70%;
}

.dms {
  width: 40%;
}

.dml {
  width: 60%;
}

.diq {
  width: 50%;
}

iframe {
  display: block;
}

.text-bold {
  font-weight: bold;
}

.button:hover {
  background-color: var(--clr-black-button);
}

.button {
  font-weight: 700;
  letter-spacing: 1.1px;
  color: var(--clr-white);
  font-size: var(--a-fontsize);
  text-transform: uppercase;
  padding: 1.3em 2.5em;
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
  background-color: var(--clr-blue);
  transition: .2s background-color ease;
}

.navbar {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 1.4em 2em 2em 2em;
  font-weight: 600;
  background-color: var(--clr-blue);
}

.button-header{
  padding: 20px 30px;
  align-self: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  color: var(--clr-blue);
}
.navbar-logo-image{
  position: relative;
}
.id{
  position: absolute;
  font-weight: bold;
  color: #fff;
  right: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.responsability{
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.responsability p{
  font-size: 14px;
}
.navbar-redirect{
  position: relative;
}
.line{
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #fff;
  top: 35px;
}
.navbar2 {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 5em 1.5em 2em;
  height: 100px;
  background-color: var(--clr-blue);
  background-color: transparent;
  z-index: 1001;
  /*box-shadow:  inset 0 0px 0 0px transparent;
  transition: .3s box-shadow ease;*/
  background-image: linear-gradient(var(--clr-blue), var(--clr-blue));
  background-size: 100% 0%;
  background-repeat: no-repeat;
  background-position: top;
  transition: .3s background-size ease;
  pointer-events: none;
}

.navbar2.active {
  background-size: 100% 100%;
  pointer-events: unset;
  /*box-shadow: inset 0 -100px 0 0px var(--clr-blue);*/
}

.hidden {
  opacity: 0;
  transition: .2s opacity ease;
}

.hidden.reveal {
  opacity: 1;
}

.immunotec {
  height: 100%;
  position: relative;
}

main,
footer {
  position: relative;
  right: 0;
  transition: .3s right ease;
}

main.active,
footer.active {
  right: 350px;
}

header {
  position: relative;
  height: auto;
}

.slider {
  display: flex;
  position: relative;
  max-height: 760px;
  width: 100%;
  right: 0;
  transition: .3s right ease;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.slider.active {
  right: 350px;
}

.slideimage {
  opacity: 0;
  transition: 1s opacity ease-in-out;
}

.prevslide {
  position: relative;
  top: 0;
  left: 0;
}

.posslide {
  position: absolute;
  top: -60px;
}

.uil-angle-left-b,
.uil-angle-right-b {
  cursor: pointer;
  position: absolute;
  width: 0px;
  color: rgba(255, 255, 255, 1);
  font-size: 54px;
}

.uil-angle-left-b {
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
}

.uil-angle-right-b {
  top: 50%;
  right: 90px;
  transform: translate(-50%, -50%);
}

.slideimage.active {
  opacity: 1;
}

.redirect-links {
  display: flex;
  gap: 20px;
}

.up,
.down {
  display: flex;
  gap: 30px;
}

main {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-centered {
  text-align: center;
  align-items: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1100px;
  padding: 6em 0em;
  gap: 50px;
}
.goleft.active{
  left: 0;
  opacity: 1;
}
.goleft{
  opacity: 0;
  position: relative;
  left: -60px;
  transition: all .8s ease-out;
}
.content-centered {
  justify-content: center;
  align-items: center;
}

.flags {
  width: 80%;
}

section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-blue {
  color: var(--clr-white);
  background-color: var(--clr-blue);
}

.bordered-blue {
  border-bottom: 5px solid var(--clr-blue);
}

.card-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 250px;
}

.card-text {
  text-align: center;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-padding {
  max-width: 340px;
  background-color: var(--clr-white);
  padding: 1.2em 1.5em 2em 1.5em;
}

.text-card {
  font-size: var(--span-text-card);
}

.information-data {
  display: flex;
  height: max-content;
  width: 100%;
}

.fs-big {
  font-weight: 900;
  font-size: var(--span-big);
}

.flex-centered {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 20px;
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
  justify-content: center;
}

.btn-white {
  background-color: var(--clr-white);
  color: var(--clr-blue);
}

.btn-white:hover {
  color: var(--clr-white);
}

.column {
  gap: 30px;
  flex-direction: column;
}

.underlined-text {
  width: 100%;
  align-items: center;
  gap: 20px;
  display: flex;
  height: 60px;
  border-bottom: 1px solid #4f94e6;
}

.underlined-text span {
  width: 10px;
}

.row-padding {
  padding: 0 1em;
}

.nogap {
  gap: 0;
}

.mb {
  margin-bottom: 20px;
}

.mbs {
  margin-bottom: 10px;
}

.bgap {
  gap: 100px;
}

.right-border {
  padding: 0 1em;
  border-right: 1px dashed #4f94e6;
}

.justify {
  text-align: justify;
}

.sgap {
  gap: 15px;
}

.mgap {
  gap: 30px;
}

.text-centeredd {
  align-self: center;
}

.text-small {
  font-size: var(--p-fontsize-small);
}

.flexbottom {
  justify-content: end;
}

.card-image {
  height: 100%;
}

.flex-box {
  display: flex;
  gap: 20px;
  width: 100%;
}

.flex-images {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.flex-images img {
  border-radius: 50%;
  width: 190px;
}

.flex-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.flex-item p {
  width: 80%;
}

.grid1,
.grid3 {
  width: 44%;
}

.grid2 {
  width: 12%;
}

.flex-images .books {
  width: 300px;
}

.books {
  width: max-content;
}

.flex-item .full-width {
  width: 100%;
}

.h1-mod {
  font-size: var(--span-h1);
}

.bg-orange {
  background-color: var(--clr-orange);
  color: var(--clr-white);
}

.text-collection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-green {
  background-color: var(--clr-light-green);
  color: var(--clr-white);
}

.listed-text {
  padding: 0em 0em 0em 2.5em;
}

.listed-text ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.team-image {
  padding: .8em;
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.2);
}

footer {
  padding: 1.8em 0em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #000;
  justify-content: center;
  align-items: center;
  color: #838383;
}

footer p {
  font-size: var(--a-fontsize);
}

footer .logo {
  width: 180px;
}

.whatsapp {
  position: fixed;
  z-index: 1000;
  display: flex;
  right: 40px;
  user-select: none;
  cursor: pointer;
  bottom: 25px;
  padding: .5em 1.5em;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: var(--clr-dark-green);
  color: var(--clr-white);
  font-weight: 500;
  transition: .2s background-color ease;
}

.uil-whatsapp {
  font-size: 20px;
}

.whatsapp:hover {
  background-color: var(--clr-light-green);
}

.whatsapp-active {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  right: 40px;
  bottom: 80px;
  width: 400px;
  border-collapse: collapse;
  box-shadow: 0 8px 25px -5px rgba(45, 62, 79, 0.2);
  pointer-events: none;
  transition: .2s all ease-in-out;
  opacity: 0;
}

.wsup {
  display: flex;
  gap: 10px;
  color: var(--clr-white);
  width: 100%;
  flex-direction: column;
  background-color: var(--clr-dark-green);
  padding: 1.6em 0em 1.6em 2em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.wsup h1 {
  font-weight: 700;
}

.wsdown {
  display: flex;
  background-color: var(--clr-white);
  padding: 2em 0em 2em 2em;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  min-height: 150px;
  cursor: pointer;
  user-select: none;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
}

.wsdown img {
  width: 50px;
}

.wstext {
  display: flex;
  flex-direction: column;
}

.text-gray {
  color: #acacac;
}

.whatsapp-active.active {
  bottom: 100px;
  pointer-events: unset;
  opacity: 1;
}

.whatsapp-active::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 80%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 10px;
  width: 10px;
  z-index: 1001;
  background-color: #fff;
}

.uil-bars {
  padding: 0;
  margin: 0;
  font-size: 28px;
  border-radius: 50%;
  color: var(--clr-white);
  display: none;
  transition: .2s color ease;
}

.uil-bars:hover {
  color: rgba(255, 255, 255, 0.6)
}

.uil-bars.active {
  color: rgba(255, 255, 255, 0.6)
}

@media (max-width:1080px) {
  p,
  span{
      font-size: 16px;
  }
  .row,
  .up,
  .down,
  .information-data {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .information-data {
      gap: 50px;
  }
  .flex-box {
      justify-content: space-evenly;
      flex-wrap: wrap;
  }
  .grid2,
  .grid1,
  .grid3 {
      width: 300px;
  }
  .dl,
  .ds,
  .dms,
  .dml,
  .diq {
      align-items: center;
      width: 100%;
  }
  .container {
      width: 90%;
  }
  .flex-item p {
      width: 100%;
  }
  .flex-images img {
      border-radius: 50%;
      width: 170px;
  }
  .flex-centered {
      flex-direction: column;
      text-align: center;
      width: 100%;
  }
  .right-border {
      padding: 0 0em;
      border-right: unset;
  }
  .bgap {
      gap: 50px;
  }
  h1 {
      font-weight: 700;
  }
  .bsbook {
      width: 250px;
  }
  .logo {
      /*width: 200px;*/
  }
  .modflex {
      flex-direction: row;
      flex-wrap: wrap;
  }
  .omegabs {
      align-self: center;
      width: 350px;
  }
  .card-image {
      align-items: center;
      justify-content: center;
      max-width: 300px;
  }
   :root {
      --h1-fontsize: 36px;
      --h2-fontsize: 22px;
      --span-text-card: 25px;
      --span-h1: 26px;
      --span-big: 56px;
      --h3-fontsize: 20px;
      --p-fontsize: 16px;
      --p-fontsize-small: 14px;
      --a-fontsize: 13px;
      --number-fontsize: 57px;
  }
  .goleft{
      left: 0px;
  }
}

@media (max-width:720px) {
  .image-container {
      display: none;
  }
}

@media (max-width:600px) {
  .whatsapp-active {
      right: 0px;
      bottom: 0px;
      width: 100%;
  }
}

@media (max-width:1120px) {
  a {
      font-size: 12px;
  }
}

@media (max-width:1065px) {
  .uil-bars {
      cursor: pointer;
      display: unset;
  }
  .button-header{
    display: none;
  }
  .line{
    display: none;
  }
  .navbar-redirect.active li{
      display: flex;
      padding-bottom: 1em;
      border-bottom: 1px solid #212b46;
  }
  .link-item {
      color: var(--clr-white);
      font-size: 0px;
      transition: .2s font-size ease;
  }
  .link-item.active {
      font-size: 12px;
  }
  .navbar-redirect {
      position: fixed;
      display: flex;
      flex-direction: column;
      background-color: var(--clr-responsive);
      right: 0;
      top: 100px;
      width: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      transition: .3s width ease;
      z-index: 2000;
  }
  .navbar-redirect.active {
      width: 350px;
      border-bottom: 2px solid var(--clr-black);
      border-top: 2px solid var(--clr-black);
  }
  .navbar {
      flex-direction: row;
      height: 100px;
      padding: 1.5em 5em 1.5em 2em;
  }
  .navbar.active{
      position: fixed;
      z-index: 5000;
      width: 100%;
  }
  .redirect-links {
      flex-direction: column;
      gap: 40px;
  }
}

@media(max-width:380px) {
  .omegabs {
      width: 100%;
  }
  .supersmall {}
  .supersmall img {
      width: 100px;
  }
  .whatsapp {
      position: fixed;
      z-index: 1000;
      display: flex;
      right: 40px;
      user-select: none;
      cursor: pointer;
      bottom: 25px;
      padding: .5em 1.5em;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: var(--clr-dark-green);
      color: var(--clr-white);
      font-weight: 500;
      transition: .2s background-color ease;
  }
}

@media(max-width:500px) {
  .logo {
      width: 170px;
  }
}